import { default as indexeo66jM28fYMeta } from "/vercel/path0/pages/exchange/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexvrx28GESt5Meta } from "/vercel/path0/pages/policy/index.vue?macro=true";
export default [
  {
    name: "exchange",
    path: "/exchange",
    meta: indexeo66jM28fYMeta || {},
    component: () => import("/vercel/path0/pages/exchange/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexvrx28GESt5Meta || {},
    component: () => import("/vercel/path0/pages/policy/index.vue")
  }
]