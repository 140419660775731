export default defineNuxtPlugin((nuxtApp) => {
    if (import.meta.client) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NLBQKH8Z';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'GTM-NLBQKH8Z');
    }
});
